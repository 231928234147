import { ConfigBaseURL } from './config.js'
import axios from 'axios'

const http = (that, options) => {
	return new Promise((resolve, reject) => {
		that.loading = true
		try {
            let ctype = (options.header=='json'?'application/json':'application/x-www-form-urlencoded')
			axios({
				url: ConfigBaseURL + options.url,
				method: options.method || 'POST', // 默认为POST请求
				data: options.data,
				headers: {
                    'Content-Type':ctype,
                   'token': 111111
                }
			})
			.then(function(res) {
				that.loading = false
				resolve(res.data)
			})
			.catch(function (err) {
				that.loading = false
				console.log(err)
				that.$tool.toast('网络异常，请稍后再试')
				reject();
			})
		}  catch (e) {
			that.loading = false
			console.log(e)
			that.$tool.toast('网络异常，请稍后再试')
			// reject(e);
		}
	})
}
export default http

/*公用api接口处理*/
export default {
    /* 获取字典数据  async await*/
    apiGetDict(that, key) {
        return this.apiResData(that, '/zjq/mainpage/GetDict', {type: key});
    },
    /* 获取首页新闻数据*/
    apiGetMainPC(that, key) {
        return this.apiResData(that, '/zjq/mainpage/GetMainPC', {type: key});
    },
    /* 获取新闻资讯 */
    apiGetNews(that, param) {
        return this.apiResData(that, '/zjq/College/GetNews',param);
    },
    /* 统一通用接口 -> 直接获取res.data  @url 后端接口地址 */
    apiHttp(that, url, h, m, p = {}, load = false) {
        return new Promise((resolve, reject) => {
            that.$http(that, {
                url,
                header: h,
                data: p,
                method: m,
                load,
            }).then((res) => {
                if (res.code == 0) {
                    resolve(res.data);
                } else {
                    reject();
                }
            })
        });
    },
    
    apiResData(that, url, param = {}, load = false) {
        return this.apiHttp(that, url, 'form', 'POST', param, load);
    },
    apiJsonData(that, url, param = {}, load = false) {
        return this.apiHttp(that, url, 'json', 'POST', param, load);
    },
    apiGet(that, url, param = {}, load = false) {
        return this.apiHttp(that, url, 'form', 'GET', param, load);
    },
    /*  统一通用接口 -> 直接获取res.data.list  @url 后端接口地址*/
    // apiResDataList(that, url, param = {}, load = false) {
    //     return new Promise((resolve, reject) => {
    //         that.$http(that, {
    //             url,
    //             header: 'form',
    //             data: param,
    //             load
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data.list);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    /*  统一通用接口GET -> 直接获取res.data.list  @url 后端接口地址*/
    // apiGetList(that, url, param = {}, load = false) {
    //     return new Promise((resolve, reject) => {
    //         that.$http(that, {
    //             url,
    //             header: 'form',
    //             method: 'GET',
    //             data: param,
    //             load
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data.list);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    /* 获取学校荣誉*/
    // apiGetSchoolAwards(that, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/GetSchoolAwards',
    //             header: 'form',
    //             data: param
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 高职高专院校、中等职业学校区域学校数量 */
    // apiGetYxsl(that) {
    //     that.$http({
    //         url: '/zjq/College/GetYxsl',
    //         header: 'form',
    //         data: {
    //             type: that.type == '中职' ? '2' : '1',
				// sfbkcc:  that.type == '高职' ? '0' : '1',
    //             pageSize: 100,
    //         }
    //     }).then(res => {
    //         if (res.code == 0) {
    //             let data = res.data.map(item => {
    //                 return {
    //                     ...item,
    //                     desc: `${item.number || 0} 所${that.type}`,
    //                     name: item.provincename,
    //                     IPC: item.ipc == 'ipc' ? this.$IMG_URL + '/indexIcon/city.png' : item.ipc
    //                 }
    //             })
    //             // data.splice(data.findIndex(t => t.name === '全国'), 1); //去除掉name为全国
    //             that.categoryArr = data;
    //         }
    //     })
    // },
    // /* 下载文件 */
    // apiGetFileDown(that, fileId) {
    //     let userInfo = uni.getStorageSync('userInfo');
    //     if (userInfo.email) {
    //         uni.showModal({
    //             content: '是否确认下载文件!',
    //             success: function (res) {
    //                 if (res.confirm) {
    //                     that.$http({
    //                         url: '/zjq/mainpage/GetFileDown',
    //                         header: 'form',
    //                         data: {
    //                             token: uni.getStorageSync('token'),
    //                             fileid: fileId
    //                         }
    //                     }).then((res => {
    //                         if (res.code == 0) {
    //                             uni.showToast({
    //                                 title: '文件已发送至您的邮箱，请注意查收！',
    //                                 icon: "none"
    //                             });
    //                         } else {
    //                             uni.showToast({
    //                                 title: res.message,
    //                                 icon: "none"
    //                             });
    //                         }
    //                     }))
    //                 } else {
    //                     uni.showToast({
    //                         title: '已取消',
    //                         icon: "none"
    //                     });
    //                 }
    //             }
    //         });
    //     } else {
    //         uni.showModal({
    //             content: '请绑定邮箱进行下载!',
    //             success: function (res) {
    //                 if (res.confirm) {
    //                     uni.navigateTo({
    //                         url: '/pages/personal/account/account'
    //                     })
    //                 }
    //             }
    //         });
    //     }
    // },
    // /* 获取文件列表 */
    // apiGetFile(that, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/mainpage/GetFile',
    //             header: 'form',
    //             data: param,
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data.list);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 院校 - 添加关注和取消关注 */
    // apiFavorite(that, sid) {
    //     that.$http({
    //         url: '/zjq/User/Favorite',
    //         header: 'form',
    //         load: true,
    //         data: {
    //             token: uni.getStorageSync('token'),
    //             sid: sid,
    //             type: that.hasSC ? '2' : '1'
    //         }
    //     }).then(res => {
    //         if (res.code == 0) {
    //             that.hasSC = !that.hasSC;
    //         }
    //         uni.showToast({
    //             title: res.message,
    //             icon: 'none',
    //             duration: 2000
    //         });
    //     });
    // },
    // /* 专业 - 添加关注和取消关注 */
    // apiFavoriteZy(that, zyid) {
    //     that.$http({
    //         url: '/zjq/User/FavoriteZy',
    //         header: 'form',
    //         load: true,
    //         data: {
    //             token: uni.getStorageSync('token'),
    //             zyid: zyid,
    //             type: that.hasSC ? '2' : '1'
    //         }
    //     }).then(res => {
    //         if (res.code == 0) {
    //             that.hasSC = !that.hasSC;
    //         }
    //         uni.showToast({
    //             title: res.message,
    //             icon: 'none',
    //             duration: 2000
    //         });
    //     });
    // },
    // /* 获取关注列表 @id 判断是否已关注 @type 关注类型（1：学校；2：专业） @key 搜索关键字*/
    // apiGetFavoriteList(that, type, id) {
    //     that.$http({
    //         url: '/zjq/User/GetFavoriteList',
    //         header: 'form',
    //         data: {
    //             pageIndex: 1,
    //             pageSize: 1000,
    //             type,
    //             token: uni.getStorageSync('token')
    //         }
    //     }).then(res => {
    //         if (res.code == 0) {
    //             if (id) {
    //                 let name = type == '1' ? 'schoolno' : 'majorcode'
    //                 let hasSC = res.data.list.some((item) => {
    //                     return item[name] == id
    //                 })
    //                 if (hasSC)
    //                     that.hasSC = true;
    //             } else {
    //               let name = 'favoriteList' + type
    //               that[name] = res.data.list
    //             }
    //         }
    //     });
    // },
    // /* 查询关注列表  @type 类型 1:学校 2:专业 */
    // apiGetFavoriteListSearch(that, type, key) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/User/GetFavoriteList',
    //             header: 'form',
    //             data: {
    //                 key,
    //                 type,
    //                 token: uni.getStorageSync('token')
    //             }
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data.list);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 获取对比库  @type 类型 1:学校 2:专业 */
    // apiGetComparison(that, type) {
    //     return new Promise((resolve, reject) => {
    //         let token = uni.getStorageSync('token')
    //         if (!token) {
    //             resolve([]);
    //         } else {
    //             that.$http({
    //                 url: '/zjq/User/GetComparison',
    //                 header: 'form',
    //                 load: true,
    //                 data: {
    //                     type,
    //                     token: uni.getStorageSync('token')
    //                 }
    //             }).then((res) => {
    //                 if (res.code == 0) {
    //                     resolve(res.data);
    //                 } else {
    //                     resolve([]);
    //                 }
    //             })
    //         }

    //     });
    // },
    // /* 操作对比库 @optype 操作类型，A新增、D删除 @type 对比类型，1学校、2专业*/
    // apiMyComparison(that, optype, type, id) {
    //     let n = type == 1 ? 'sid' : 'majorCode'
    //     let param = {}
    //     param[n] = id
    //     param = {
    //         ...param,
    //         optype,
    //         type,
    //         token: uni.getStorageSync('token')
    //     }
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/User/MyComparison',
    //             header: 'form',
    //             data: param,
    //             load: true
    //         }).then(res => {
    //             uni.showToast({
    //                 title: res.message,
    //                 icon: 'none'
    //             });
    //             if (res.code == 0) {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         });
    //     });
    // },
    // /* 获取院校  @key 关键字*/
    // apiGetSchoolSearchList(that, key, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/GetSchoolSearchList',
    //             header: 'form',
    //             data: {
    //                 ...param,
    //                 key
    //             },
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data.list);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 获取专业  @key 关键字*/
    // apiGetMajors(that, key, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/GetMajors',
    //             header: 'form',
    //             data: {
    //                 ...param,
    //                 key: key || '',
    //                 token: uni.getStorageSync('token')
    //             },
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data.list);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 获取热门专业和学校  @type 1:专业，2：学校（默认2）*/
    // apiGetHotCollege(that, type, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/mainpage/GetHotCollege',
    //             header: 'form',
    //             data: {
    //                 ...param,
    //                 type
    //             },
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data.list);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 专业设置 - 获取专业 @pageIndex 页数, @pageSize 每页多少条*/
    // apiGetCollegeMajorSetting(that, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/GetCollegeMajorSetting',
    //             header: 'form',
    //             data: {
    //                 ...param,
    //                 token: uni.getStorageSync('token')
    //             },
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data.list);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 专业设置 - 获取院校专业学生分布 */
    // apiGetXxzyfb(that, sid) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/GetXxzyfb',
    //             header: 'form',
    //             load: true,
    //             data: {
    //                 sid,
    //                 token: uni.getStorageSync('token')
    //             },
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 学校/专业搜索 @type 1:专业，2：学校（默认2） @key 关键字*/
    // apiGetSchoolMajorHighLightSearchList(that, type = 2, key, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/GetSchoolMajorHighLightSearchList',
    //             header: 'form',
    //             data: {
    //                 ...param,
    //                 key,
    //                 type,
    //                 token: uni.getStorageSync('token')
    //             },
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data.list);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 学校对比结果  @sids 逗号隔开的id*/
    // apiMyXxdb(that, sids) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/User/MyXxdb',
    //             header: 'form',
    //             data: {
    //                 sids,
    //                 token: uni.getStorageSync('token')
    //             },
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 专业对比结果  @sids 逗号隔开的id*/
    // apiMyZydb(that, zyids) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/User/MyZydb',
    //             header: 'form',
    //             data: {
    //                 zyids,
    //                 token: uni.getStorageSync('token')
    //             },
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 点赞和取消点赞  @keyid 学校代码或专业代码 @type 1学校，2专业 @isDz false点赞，true取消点赞*/
    // apiLikeit(that, type, keyid, isDz) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: `/zjq/User/${isDz?'dontlikeit':'likeit'}`,
    //             header: 'form',
    //             load: true,
    //             data: {
    //                 keyid,
    //                 type,
    //                 token: uni.getStorageSync('token')
    //             },
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 创业就业率 */
    // apiGetJycy(that, sid) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/GetJycy',
    //             header: 'form',
    //             data: {
    //                 sid,
    //                 token: uni.getStorageSync('token')
    //             },
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 学校技能大赛情况 */
    // apiGetSkillList(that, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/GetSkillList',
    //             header: 'form',
    //             data: {
    //                 ...param,
    //                 token: uni.getStorageSync('token')
    //             },
    //         }).then((res) => {
    //             resolve(res.data);
    //         })
    //     });
    // },
    // /* 根据专业获取开设学校列表 */
    // apiGetXxByZy(that, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/GetXxByZy',
    //             header: 'form',
    //             data: param,
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data.list);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 获取学校质量年报列表 */
    // apiGetYearReport(that, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/GetYearReport',
    //             header: 'form',
    //             data: param,
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 获取专业教学标准列表 */
    // apiGetZyJxbz(that, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/GetZyJxbz',
    //             header: 'form',
    //             data: param,
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 使用vip服务 */
    // apiUseTools(that, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/User/UseTools',
    //             header: 'form',
    //             data: {
    //                 ...param,
    //                 token: uni.getStorageSync('token')
    //             },
    //             load: true
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(res);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 发送手机验证码 */
    // apiSendSmsValidateCode(that, phone) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/User/SendSmsValidateCode',
    //             header: 'form',
    //             data: {
    //                 phone
    //             },
    //             load: true
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 that.$tool.toast(res.message)
    //                 // uni.showModal({	content: `验证码：${res.data.vcode}`,	showCancel: false });
    //                 resolve(res);
    //             } else {
    //                 that.$tool.modal(res.message);
    //                 reject();
    //             }
    //         })
    //     });
    // },
    // /* 验证手机验证码 */
    // apiVerifyCode(that, code, phone) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/User/verifyCode',
    //             header: 'form',
    //             data: {
    //                 code,
    //                 phone
    //             }
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(0); //验证码正确
    //             } else {
    //                 resolve(1);
    //             }
    //         })
    //     });
    // },
    // /* 重置密码 忘记密码 */
    // apiResetPassword(that, param = {}) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/User/ResetPassword',
    //             header: 'form',
    //             data: param,
    //             load: true
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 that.$tool.toast(res.message)
    //                 resolve(1);
    //             } else {
    //                 that.$tool.modal(res.message);
    //                 resolve(0);
    //             }
    //         })
    //     });
    // },
    // /* 登录成功->获取用户信息->跳转用户详情页 */
    // apiGetUser(that, param = {}, delta = 1) {
    //     that.$http({
    //         url: "/zjq/User/GetUser",
    //         header: "form",
    //         load: true,
    //         data: param
    //     }).then(
    //         res => {
    //             if (res.code == 0) {
    //                 uni.setStorageSync('userInfo', res.data);
    //                 that.$tool.navBack(delta);
    //             } else {
    //                 that.$tool.toast(res.message)
    //             }
    //         },
    //         err => {
    //             console.log(err);
    //         }
    //     );
    // },
    // /*  获取学校详细信息 */
    // getSchoolDetailInfo(that, params, cb) {
    //     return that.$http({
    //         url: "/zjq/College/GetCollegeDetail",
    //         header: "form",
    //         data: params
    //     })
    // },
    // /* 基础认证 */
    // getAuthenticationInfo(that, params) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/College/authmain',
    //             data: params,
    //             header: 'form',
    //         }).then((res) => {
    //             if (res.code == '0') {
    //                 resolve(res.data)
    //             } else {
    //                 that.$tool.toast(res.message)
    //                 reject();
    //             }
    //         })
    //     })
    // },
    // // 获取会议住宿列表
    // getMeetingHotel(that, params) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/meeting/meetinghotel',
    //             method: 'get',
    //             data: params
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 let z = []
    //                 for (let i of res.data) {
    //                     if (i.meetinghotelid) {
    //                         z.push({
    //                             value: i.meetinghotelid,
    //                             label: `${i.name}-${i.roomtype}(${i.price}元/间)`,
    //                             cansignupsex: i.cansignupsex //性别判断 1为只能住男的 2为只能住女的
    //                         })
    //                     } else {
    //                         z.push({
    //                             value: i.meetinghotelid,
    //                             label: i.name
    //                         })
    //                     }
    //                 }
    //                 resolve(z);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     })
    // },
    // // 获取会议列表
    // getMeetingList(that, params) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/meeting/meetingList',
    //             method: 'GET',
    //             data: params
    //         }).then((res) => {
    //             if (res.code == '0') {
    //                 resolve(res.data);
    //             } else {
    //                 that.$tool.toast(res.message);
    //                 reject();
    //             }
    //         })
    //     })
    // },

    // // 我的会议列表
    // getMyMeetingList(that, params) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/meeting/myMeetingList',
    //             method: 'get',
    //             data: params
    //         }).then((res) => {
    //             if (res.code == '0') {
    //                 resolve(res.data);
    //             } else {
    //                 that.$tool.toast(res.message);
    //                 reject();
    //             }
    //         })
    //     })
    // },

    // // 我的会议,撤销报名
    // cancelMyMeeting(that, params) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/meeting/cancelmymeeting',
    //             method: 'post',
    //             header: 'form',
    //             data: params
    //         }).then((res) => {
    //             if (res.code == '0') {
    //                 resolve(0);
    //             } else {
    //                 resolve(1);
    //             }
    //             setTimeout(() => {
    //                 that.$tool.toast(res.message);
    //             }, 1);

    //         })
    //     })
    // },

    // // 删除我的会议
    // delMyMeeting(that, params) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/meeting/deletemymeeting',
    //             method: 'post',
    //             header: 'form',
    //             data: params
    //         }).then((res) => {
    //             if (res.code == '0') {
    //                 that.$tool.toast(res.message);
    //                 resolve(res);
    //             } else {
    //                 // that.$tool.toast(res.message);
    //                 reject();
    //             }
    //         })
    //     })
    // },


    // // 获取会议资料列表
    // getMeetingInfo(that, params) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/meeting/meetingDocs',
    //             method: 'get',
    //             data: params
    //         }).then((res) => {
    //             if (res.code == '0') {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     })
    // },

    // // 获取我的会议页面详情
    // getMyMeetingDetail(that, params) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/meeting/myMeetingDetail',
    //             method: 'get',
    //             load: true,
    //             data: params
    //         }).then((res) => {
    //             if (res.code == '0') {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     })
    // },


    // // 获取会议列表会议详情
    // getMeetingDetail(that, params) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/meeting/meetingDetail',
    //             method: 'get',
    //             load: true,
    //             data: params
    //         }).then((res) => {
    //             if (res.code == '0') {
    //                 resolve(res.data);
    //             } else {
    //                 reject();
    //             }
    //         })
    //     })
    // },

    // // 提交报名信息
    // signUpMeeting(that, params) {
    //     return new Promise((resolve, reject) => {
    //         that.$http({
    //             url: '/zjq/meeting/signUpMeeting',
    //             method: 'post',
    //             data: params
    //         }).then((res) => {
    //             if (res.code == 0) {
    //                 resolve(0)
    //             } else {
    //                 resolve(1)
    //             }
    //             setTimeout(() => {
    //                 that.$tool.toast(res.message);
    //             }, 1);
    //         })
    //     })
    // }


}
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/index/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/index/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../pages/index/register.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../pages/index/search.vue')
  },
  
  // 信息资讯
  {
    path: '/news',
    name: 'news',
    component: () => import('../pages/news/news.vue')
  },
  {
    path: '/newsdetail',
    name: 'newsDetail',
    component: () => import('../pages/news/newsDetail.vue')
  },
  
  // 人才供需
  {
    path: '/need',
    name: 'need',
    component: () => import('../pages/need/need.vue')
  },
  {
    path: '/needdetail1',
    name: 'needDetail1',
    component: () => import('../pages/need/needDetail1.vue')
  },
  {
    path: '/needdetail2',
    name: 'needDetail2',
    component: () => import('../pages/need/needDetail2.vue')
  },
  {
    path: '/needdetail3',
    name: 'needDetail3',
    component: () => import('../pages/need/needDetail3.vue')
  },
  
  // 项目发布
  {
    path: '/project',
    name: 'project',
    component: () => import('../pages/project/project.vue')
  },
  {
    path: '/projectdetail',
    name: 'projectDetail',
    component: () => import('../pages/project/projectDetail.vue')
  },
  
  // 合作资源
  {
    path: '/resource',
    name: 'resource',
    component: () => import('../pages/resource/resource.vue')
  },
  
  // 人才分析
  {
    path: '/analyse',
    name: 'analyse',
    component: () => import('../pages/analyse/analyse.vue')
  },
  
  // 课证融通
  {
    path: '/papers',
    name: 'papers',
    component: () => import('../pages/papers/papers.vue')
  },
  
  // 数据中心
  {
    path: '/data',
    name: 'data',
    component: () => import('../pages/data/data.vue')
  },
  {
    path: '/data/school',
    name: 'schoolDetail',
    component: () => import('../pages/data/schoolDetail.vue')
  },
  {
    path: '/data/major',
    name: 'majorDetail',
    component: () => import('../pages/data/majorDetail.vue')
  },
  {
    path: '/data/enterprise',
    name: 'enterpriseDetail',
    component: () => import('../pages/data/enterpriseDetail.vue')
  },
  
  // 服务指南
  {
    path: '/help',
    name: 'help',
    component: () => import('../pages/help/help.vue')
  },

]

const router = new VueRouter({
  routes
})

export default router

/*公用tool数据处理*/
export default {
    getUrlCode() {
        let url = window.location.href;	//获取当前url
        let dz_url = url.split('#')[0];	//获取#/之前的字符串
        if (!dz_url) return
        let ps = dz_url.split('?')[1];	//获取?之后的参数字符串
        if (!ps) return
        let cs_arr = ps.split('&');		//参数字符串分割为数组
        if (!cs_arr) return
        let cs={};
        for(let i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
            let p = cs_arr[i].split('=')
            cs[p[0]] = p[1]
        }
        if (cs.code) return cs.code
        return ''
    },
	fullNum(n) {
		return n>9?''+n:'0'+n;
	},
	fullDay(d) {
		let m = this.fullNum(d.getMonth()+1)
		let n = this.fullNum(d.getDate());
		return d.getFullYear()+m+n;
	},
	
	toast(msg) {
		console.log(msg)
		// message.info(msg);
	},
	toBr(s) {
		if (s) return s.replace(/\n/g,'<br />')
		return ''
	},
    html2txt(html) {
        return html.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'')
                    .replace(/<[^>]+?>/g,'').replace(/\s+/g,'').replace(/ /g,'').replace(/>/g,'')
                    .replace(/&nbsp;/g,' ')
    },

	setSize(id, width, height) {
		let bodyWidth = document.body.clientWidth;
		let bodyHeight = document.body.clientHeight;
		let ratiow = bodyWidth /750>2?2:bodyWidth /750;
		let ratioh = bodyHeight/1334>2?2:bodyHeight/1334;
		console.log("ratiow:"+ratiow+" -- ratioh:"+ratioh)
		let e = document.getElementById(id);
		if (e!=null) {
			// e.style.position='absolute';
			// e.style.left = (left*ratiow) + "px";
			// e.style.top = (top*ratioh) + "px";
			e.style.width = (width*ratiow) + "px";
			e.style.height = (height*ratioh) + "px";
			console.log("w:"+e.style.width+" -- h:"+e.style.height)
		}
	},

}
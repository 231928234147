//全局请求入口
export const ConfigBaseURL = './' //测试小程序
//export const ConfigBaseURL = 'https://siryornpay.sumpros.com.cn' //测试小程序
//export const ConfigBaseURL = 'http://localhost:18082' //测试
//对比库数量
export const ConfigContLibrary = 10

//可对比数量
export const ConfigContrast = 2

// 小程序 static 访问路径。css图片变量在uni.scss
export const IMG_URL = './i-skill/static.web' //发行
export const WEB_URL = './VEM/html'
export const UPLOAD_URL = './zjq/file/upload/'

//外链接配置 
export const ConfigWeb = {
}

<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
  export default {
    data() {
      return {
      };
    },
  };
</script>
<style lang="scss">
@import 'common/common.css';

// 隐藏radio前面的圆点，只在全局设置有效
#queyParam .el-radio__input{
    display: none;
}
#queyParam .el-radio{
    padding: 5px 0 5px 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

import http from './common/http.js'
import api from './common/api.js'
import tool from './common/tool.js'
Vue.prototype.$http = http
Vue.prototype.$api = api
Vue.prototype.$tool = tool

import { IMG_URL } from '@/common/config.js'
Vue.prototype.$IMG_URL = IMG_URL


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
